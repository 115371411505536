$breakpoints: (
  "xs": 640px,
  "md": 768px,
  "lg": 1024px,
  "xl": 1366px,
);

@mixin mq($size) {
  @media screen and (min-width: #{map-get($breakpoints, $size)}) {
    @content;
  }
}

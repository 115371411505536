@import "@/assets/styles/mixins";
@import "@/assets/styles/functions";

.news{
  @include mq(md) {
    display: flex;
    align-items: center;
    gap: theme('gap.4');
  }

  .news__content {
    display: flex;
    align-items: center;
    gap: theme('gap.4');
    margin-bottom: theme("margin.2");
    @include mq(md) {
      margin-bottom: 0;
    }
  }

  .news__tag {
    display: inline-block;
    text-align: center;
    font-weight: bold;
    color: theme("colors.white");
    background-color: theme("colors.bg.blue");
    border-radius: theme("borderRadius.3xl");
    width: 122px;
    padding: theme("padding.px") theme("padding.2");
    font-size: rem(11);
    &[data-category="important"]{
      @apply bg-[#e11e2d];
    }
  }

  .news__date {
    font-weight: bold;
  }
  
  .news__title{
    font-size: theme("fontSize.sm");
    line-height: 1.5;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    @include mq(md) {
      line-height: 2;
    }
  }
}
.root{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position:relative;
  height:100%;
  font-weight: theme("fontWeight.bold");
  color: theme("colors.bg.blue");
  padding-bottom: theme("padding.3");

  &:before,
  &:after{
      content: "";
      position: absolute;
      bottom:0;
      left:0;
      width: 100%;
      height: theme("height[0.5]");
      background-color: theme("colors[bg][blue]");
      @media screen and (min-width: theme("screens.md")){
        background-color: theme("colors[white]");
      }
  }
  &:after{
      content:"";
      background: theme("colors[bg][blue]");
      transform: scaleX(0);
      transition: transform 0.7s ease-in-out;
      transform-origin: right;
  }

  .root__label{
    display: flex;
    align-items: center;
    width:100%;
    gap: theme("gap.3");
    line-height: 1.2;
    @media screen and (min-width: theme("screens.md")){
      gap: theme("gap.2");
    }
  }
  .root__icon{
    font-size: theme("fontSize.lg");
  }

  &:hover{
    &:after{
      transform: scaleX(1);
      transform-origin: left;
    }
  }
  
}
.root{  
    position:relative;
    color: theme("colors.bg.blue");
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: theme("spacing.2");
    font-weight: 700;
  &:before,
  &:after{
      content: "";
      position: absolute;
      bottom:0;
      left:0;
      width: 100%;
      height: 2px;
      background-color: theme("colors.white");
  }
  &:after{
        transform-origin: right;
        transform: scaleX(1);
        transition: transform 700ms ease-in-out;
        background-color: theme("colors.bg.blue");
        @media (min-width: theme("screens.md")){
          transform: scaleX(0);
        }
  }
  &:hover{
      &:after{
        transform-origin: left;
        transform: scaleX(1);
      }
  }
}